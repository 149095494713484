$theme: (
  --colors-primary: #955fe1,
  --colors-primary-hover: #8053c1,

  --colors-primary-light: #9900f5,
  --colors-primary-light-hover: #7400ba,

  --colors-primary-dark: #25094c,
  --colors-primary-dark-hover: #472676,

  --colors-gray: #6c757d,
  --colors-gray-hover: #52575a,

  --colors-success: #00d7be,
  --colors-success-hover: #00ad9a,

  --colors-info: #1fbed9,
  --colors-info-hover: #20afc7,

  --colors-warning: #e3a62c,
  --colors-warning-hover: #d39e36,

  --colors-danger: #d75353,
  --colors-danger-hover: #bf3636,

  --colors-dark: #17212d,
  --colors-dark-hover: #2c3239,

  --fonts-default: (
    Versos,
    sans-serif,
  ),
);
