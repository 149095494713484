.messages-wrapper {
    list-style-type: none;
    display: flex;
    flex-flow: column-reverse;
    overflow: auto;
    height: 100vh;

    padding: 0 10px 10px 10px;
    margin: 5px 0;

    border: 1px solid rgba(54, 64, 74, 0.08);
    border-radius: 5px;

    .message {
        display: flex;
        flex-shrink: 0;
        width: 100%;
        height: fit-content;
        margin-bottom: 2px;

        &.mark {
            justify-content: center;
            width: 100%;
            margin: 15px 0 17px;

            mark {
                color: var(--colors-primary) !important;
                font-weight: 600;
                background-color: transparent;
            }
        }

        .conversation-text {
            padding: 7px 5px 3px 10px;
            margin: 0px 5px;
            border-radius: 10px;
            font-size: 14px;
            max-width: 80%;
            background: #f5f5f5;
            height: fit-content;
            display: flex;
            align-items: center;
            flex-flow: column;
            min-width: 70px;

            p {
                margin: 0;
                word-break: break-word;
                white-space: pre-wrap;
                line-height: 15px;
                text-align: start;
                width: 100%;
            }

            .conversation-time {
                line-height: 10px;
                width: 100%;
                text-align: end;
                font-size: 10px;
                margin-top: 2px;
                color: #adadad;
            }
        }

        &.receiver {
            justify-content: end;
            flex-flow: row-reverse;

            .conversation-text {
                background: var(--colors-primary);
                text-align: right;
                p {
                    color: #ffffff;
                }
                .conversation-time {
                    color: #e0e0e0;
                }
            }
        }
    }

    .back-start-btn {
        position: absolute;
        right: 20px;
        margin-bottom: 10px;

        button {
            width: 35px;
            height: 35px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: none;

            background-color: var(--colors-primary-dark);
            box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.527);

            &:hover {
                background: var(--colors-primary-dark-hover);
            }
        }
    }

    .loading-more {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 15px 0;
        box-sizing: border-box;
    }
}
