@use "theme.scss";

html,
body,
#root {
    height: 100%;
}

body {
    margin: 0 !important;

    font-family: var(--fonts-default);
    font-weight: 500;
    line-height: 1.5;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: var(--colors-primary);
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
    background-color: #f5f5f5;
}

// animacao ao abrir conversa
.slider-enter.left > .component {
    transform: translate3d(100%, 0, 0);
}

.slider-enter.right > .component {
    transform: translate3d(-100%, 0, 0);
}

.slider-exit.left > .component {
    transform: translate3d(-100%, 0, 0);
}

.slider-exit.right > .component {
    transform: translate3d(100%, 0, 0);
}

.slider-enter.slider-enter-active > .component {
    transform: translate3d(0, 0, 0);
    transition: all 200ms ease-in-out;
}

// estilos sweet alert
.swal2-container.swal2-top > .swal2-popup {
    justify-self: center;
    width: 100%;
    padding: 0;
    border: 1px solid var(--colors-primary-dark);
    color: var(--colors-primary);

    &.error {
        display: flex !important;
        background: var(--colors-danger);
        padding: 5px 10px;
        .swal2-icon {
            width: 20px;
        }
        .swal2-html-container {
            color: #f5f5f5;
            font-size: 12px;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
}

.swal2-popup.swal2-toast .swal2-html-container {
    padding: 10px 15px;
    margin: 0;
    .toast-text {
        display: block;
        display: -webkit-box;
        max-height: 150px;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.chat-wrapper {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;
    min-width: 300px;

    .header {
        display: flex;
        height: 65px;
    }

    .content {
        display: flex;
        flex-flow: column;
        height: calc(100% - 65px);
        overflow: visible;
    }
}
