.status-dropdown-wrapper {
    position: absolute;
    background: white;
    z-index: 9999;
    top: 55px;
    left: 10px;

    height: fit-content;
    width: 100px;
    border-radius: 5px;

    padding: 5px 10px;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);

    .dropdown-list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        .dropdown-item {
            border-radius: 3px;
            svg {
                margin: 0 5px;
            }
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                background: rgba(105, 105, 105, 0.2);
            }
        }
    }
}

.header-wrapper {
    height: 65px;
    background-color: var(--colors-primary);
    box-sizing: border-box;
    display: flex;
    align-items: center;

    padding: 5px;
    width: 100%;

    .header-img {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 5px;
        padding-right: 20px;
        cursor: pointer;

        svg {
            display: none;
            position: absolute;
            right: 4px;
        }

        &:hover {
            svg {
                display: block;
            }
        }

        img {
            border-radius: 50%;
            height: 40px;
            width: 40px;

            border: 2px solid #dee2e6;

            &.status-disponivel {
                background-color: #4fdc45;
                border-color: #4fdc45;
            }

            &.status-ausente {
                background-color: #ff901d;
                border-color: #ff901d;
            }

            &.status-ocupado {
                background-color: #ff1d1d;
                border-color: #ff1d1d;
            }
        }

        &.chat-open {
            cursor: default;
            padding-right: 10px;
        }
    }

    .header-user-info {
        height: 50px;
        display: flex;
        flex-flow: column;
        justify-content: space-around;

        .nome-usuario {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #ffffff;
        }

        .search-contact {
            width: 215px;
            height: 25px;
            background-color: transparent;
            color: #eeeeee;
            border-color: rgba(255, 255, 255, 0.7);
            border-radius: 0px;
            border-left-style: none;
            border-right-style: none;
            border-top-style: none;
            border-bottom-width: 1px;
            font-family: var(--fonts-default);
            outline: none;
            box-sizing: border-box;

            &::placeholder {
                color: #eeeeee;
                opacity: 1;
            }
        }
    }

    .close {
        position: absolute !important;
        overflow: hidden;
        top: 5px;
        right: 5px;
        outline: none;
        width: 50px;
        height: 35px;

        .react-ripples {
            overflow: visible !important;
        }
        button {
            background: transparent;
            border: none;

            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 7px;
            width: 45px;
            height: 30px;

            transition: all 0.3s;

            &:hover {
                background: rgba(0, 0, 0, 0.2);
                box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
            }
        }
    }

    .back-button {
        display: flex;
        align-items: center;
        position: relative;
        width: 35px;
        box-sizing: border-box;

        cursor: pointer;
        height: 100%;
        padding: 5px;

        svg {
            fill: #ffffff;
        }

        .unread-badge {
            display: inline-block;
            position: absolute;
            top: 7px;
            right: 3px;
            color: #ffffff;
            background-color: #f76397;
            line-height: 1;
            font-weight: 600;
            padding: 3px 5px;
            font-size: 12px;
            border-radius: 0.25rem;
        }
    }
}
