.chat-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    height: 100%;

    .send-message-wrapper {
        display: flex;
        align-items: center;
        height: auto;
        padding: 7px 10px;

        border: 1px solid rgba(54, 64, 74, 0.08);
        border-radius: 5px;

        form {
            display: flex;
            width: 100%;

            .message-content {
                width: 100% !important;
                padding: 6px 10px;
                resize: none;
                font-family: var(--fonts-default);
                border-radius: 0.25rem 0 0 0.25rem;
                font-size: 1rem;
                line-height: 1.5;
                min-height: 40px;
                box-sizing: border-box;
                display: flex;
                outline: none;
            }

            .send-message-btn {
                height: inherit;
                border-radius: 0 0.25rem 0.25rem 0;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                padding: 0.375rem 0.75rem;
                background-color: var(--colors-primary);
                outline: none;

                svg path {
                    stroke: #ffffff;
                }
            }
        }
    }
}
