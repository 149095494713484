.component {
    display: flex;
    flex-flow: column;
    height: 100%;

    .support-cards {
        display: flex;
        flex-flow: column;
        box-sizing: border-box;
    }
}
.list {
    margin: 5px 0;
    overflow: auto;
    border: 1px solid rgba(54, 64, 74, 0.08);
    display: flex;
    flex-flow: column;

    padding: 0 5px;
}

@keyframes pulse {
    from {
        background-color: white;
    }
    to {
        background-color: var(--pulse-color);
    }
}

.chat {
    user-select: none;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 5px;

    height: fit-content;

    &.notif {
        animation: 1s 2s pulse 6 alternate;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid #f5f5f5;
    }

    &.hide {
        display: none !important;
    }

    &.support-card {
        padding: 0 15px;
        margin-top: 5px;
        border: 1px solid rgba(54, 64, 74, 0.08);
    }

    .chat-img {
        float: left;
        display: block;
        margin-right: 20px;

        img {
            display: flex;
            align-items: center;

            border-radius: 50%;
            height: 40px;
            width: 40px;

            border: 2px solid #dee2e6;

            &.status-disponivel {
                background-color: #4fdc45;
                border-color: #4fdc45;
            }

            &.status-ausente {
                background-color: #ff901d;
                border-color: #ff901d;
            }

            &.status-ocupado {
                background-color: #ff1d1d;
                border-color: #ff1d1d;
            }

            &.support {
                background-color: transparent;
            }
        }
    }

    &:hover {
        background: rgba($color: #000000, $alpha: 0.08);
    }

    .chat-info {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;

        .user-name {
            font-size: 0.95rem;
            color: #2a3142;
        }

        .message-row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            font-size: 12px;
            color: #98a6ad;

            &.support-card {
                justify-content: flex-start;
            }

            .last-message {
                display: flex;
                align-items: center;
                width: 100%;

                svg {
                    fill: #00b19d !important;
                    margin-right: 2px;
                }
                .message {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 120px;
                }
            }

            .message-date {
                width: fit-content;
                white-space: nowrap;
                flex-shrink: 0;
            }
        }
    }

    .unread-badge {
        display: inline-block;
        position: absolute;
        top: 7px;
        right: 10px;
        color: #ffffff;
        background-color: #00b19d;
        line-height: 1;
        font-weight: 600;
        padding: 3px 5px;
        font-size: 12px;
        border-radius: 0.25rem;
    }
}
