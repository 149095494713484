@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-Thin.woff") format("woff");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-Light.woff") format("woff");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-SemiBold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-Black.woff") format("woff");
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Versos";
  src: url("../assets/fonts/versos/Versos-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
